import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CheckTokenResponse } from '../models/check-token-response';
import { User } from '../models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class ValidateTokenService {
  private http = inject(HttpClient);

  private baseUrl: string = environment.baseUrl;

  isLogged = signal<boolean>(false);
  private _user = signal<User | null>(null);

  get user(): User | null {
    return this._user();
  }

  get role(): string | undefined {
    return this._user()?.role;
  }

  validateToken(): Observable<boolean> {
    const url = this.baseUrl + '/auth/check-token';

    return this.http.get<CheckTokenResponse>(url).pipe(
      tap((resp) => {
        this._user.set(resp.user);
        localStorage.setItem('token', resp.token);
        localStorage.setItem('username', this._user()!.name);
      }),
      map(() => {
        this.isLogged.set(true);

        return true;
      }),
      catchError((err) => {
        this.isLogged.set(false);

        return throwError(() => err.error);
      }),
    );
  }
}
