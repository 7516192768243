import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app/app.routes';
import { importProvidersFrom } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { createTranslateLoader } from './app/config/translateLoader';
import { inMemoryScrollingFeature } from './app/config/scroll-config';
import { authTokenInterceptor } from './app/shared/interceptors/auth-token.interceptor';

if (!navigator.geolocation) {
  alert('Geolocation is not supported by this browser.');
  throw new Error('Geolocation is not supported by this browser.');
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptors([authTokenInterceptor])),
    provideRouter(routes, inMemoryScrollingFeature),
    provideAnimations(),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ]),
  ],
}).catch((err) => console.error(err));
