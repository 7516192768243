import { Routes } from '@angular/router';
import { isLoggedGuard } from './shared/guards/is-logged.guard';
import { hasRoleGuard } from './shared/guards/has-role.guard';
import { notLoggedGuard } from './shared/guards/not-logged.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [notLoggedGuard],
    loadChildren: () => import('./landing/landing.routes'),
  },
  {
    path: 'app',
    canMatch: [isLoggedGuard],
    canActivate: [hasRoleGuard(['driver', 'company'])],
    loadChildren: () => import('./app/app.routes').then((r) => r.routes),
  },
  {
    path: 'dashboard',
    canMatch: [isLoggedGuard],
    canActivate: [hasRoleGuard(['admin'])],
    loadChildren: () => import('./dashboard/dashboard.routes'),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routing').then((r) => r.routes),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
