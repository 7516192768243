import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ValidateTokenService } from '@src/app/auth/services/validate-token.service';
import { map, tap } from 'rxjs';

export const hasRoleGuard = (allowedRoles: string[] = []) => {
  return () => {
    const router = inject(Router);
    const validateTokenService = inject(ValidateTokenService);

    return validateTokenService.validateToken().pipe(
      tap((hasRole) => {
        if (!hasRole) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');

          router.navigate(['/auth/login']);
        }

        if (validateTokenService.user?.role === 'user') {
          router.navigate(['/auth/role-selector']);
        }
      }),
      map(() => {
        return Boolean(
          validateTokenService.user &&
            allowedRoles.includes(validateTokenService.user.role),
        );
      }),
    );
  };
};
